<template>
    <v-card>
		<v-form class="pa-2" @submit.prevent="SaveReward">
			<v-card-title>
				<span class="text-h5 text-center">New Reward</span>
			</v-card-title>

			<v-card-text>
				<v-container>
					<v-row>
						<v-col cols="12">
							<h2>Category</h2>
						</v-col>

						<v-col cols="4">
							<v-select
								flat
								solo
								v-model.trim="$v.v_model_category.$model"
								:error-messages="categoryErrors"
								@change="loadSubCat(v_model_category)"
								@input="$v.v_model_category.$touch()"
								@blur="$v.v_model_category.$touch()"
								:items="categories_names" 
								label="Select Reward Category"
								required
							></v-select>
						</v-col>

						<v-col cols="4">
							<v-select
								flat
								solo
								v-if="server_hosts_names.length > 0"
								v-model="v_model_server_host"
								:items="server_hosts_names"
								label="Select FiveM Server"
								required
							></v-select>
						</v-col>

						<v-col cols="4">
							<v-select
								flat
								solo
								v-if="server_reward_categories_names.length > 0"
								v-model="v_model_server_reward_category"
								:items="server_reward_categories_names"
								label="FiveM Server Reward Category"
								required
							></v-select>
						</v-col>

						<v-col cols="4">
							<v-text-field 
								clearable
								v-if="server_reward_categories_names.length > 0"
								v-model="v_model_server_reward_item"
								label="FiveM Server Item/Action Name" 
								required
							></v-text-field>
						</v-col>

						<v-col cols="12">
							<h2>Info</h2>
						</v-col>

						<v-col cols="12">
							<v-text-field 
								clearable
								v-model.trim="$v.title.$model"
								:error-messages="titleErrors"
								@input="$v.title.$touch()"
								@blur="$v.title.$touch()"
								label="Title*" 
								required
							></v-text-field>
						</v-col>

						<v-col cols="12">
							<v-textarea 
								clearable
								v-model.trim="$v.description.$model"
								:error-messages="descriptionErrors"
								@input="$v.description.$touch()"
								@blur="$v.description.$touch()"
								label="Description*" 
								required
							></v-textarea>
						</v-col>

						<v-col cols="12">
							<h2>Points</h2>
						</v-col>

						<v-col cols="6" v-for="streamer in streamers" :key="streamer.id">
							<v-text-field
								v-model="streamer.points"
								:label="streamer.shorthand + ' points'"
								type="number"
								clearable
							></v-text-field>
						</v-col>

						<v-col cols="12">
							<h2>Options</h2>
						</v-col>

						<v-col cols="6">
							<v-checkbox v-model="repeatable" label="Repeatable Reward"></v-checkbox>
						</v-col>
						<v-col cols="6">
							<v-checkbox v-model="steam_auth_required" label="Require Steam Auth"></v-checkbox>
						</v-col>
						<v-col cols="6">
							<v-checkbox v-model="has_max_redeems" label="Max Redeems"></v-checkbox>
						</v-col>
						<v-col cols="6">
							<v-checkbox v-model="has_max_redeems_per_user" label="Max Redeems Per User"></v-checkbox>
						</v-col>
						<v-col cols="6">
							<v-checkbox v-model="has_multiple_redeems" label="Multiple Redeems"></v-checkbox>
						</v-col>
						<v-col cols="6">
							<v-checkbox v-model="notify_in_chat" label="Send Chat Alert"></v-checkbox>
						</v-col>
						<v-col cols="6">
							<v-text-field v-model="max_redeems" :disabled="!has_max_redeems" type="number" label="Max redeems for the reward" clearable></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field v-model="max_redeems_per_user" :disabled="!has_max_redeems_per_user" type="number" label="Max redeems for the reward per user" clearable></v-text-field>
						</v-col>
						<v-col cols="6">
							<v-text-field v-model="cooldown" type="number" hint="Cooldown is in minutes" label="Reward Cooldown" clearable required></v-text-field>
						</v-col>

						<v-col cols="12">
							<h2>Add Image</h2>
						</v-col>

						<v-col cols="12" class="mb-2">
							<v-tooltip right>
								<template v-slot:activator="{ on, attrs }">
									<v-btn
										v-bind="attrs" 
										v-on="on"
										@click="AddImageURL"
										small 
										fab
										color="pink"
									>
										<v-icon>mdi-plus</v-icon>
									</v-btn>
								</template>
								<span>Add Image URL</span>
							</v-tooltip>
						</v-col>

						<v-col cols="12" v-for="(url, index) in urls" :key="index" class="py-0">
							<v-text-field prepend-icon="mdi-image" v-model="urls[index]" label="Paste an image link here" clearable></v-text-field>
						</v-col>

					</v-row>
				</v-container>
				<small>*indicates required field</small>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="blue darken-1" text @click="$emit('CloseDialog')">Close</v-btn>
				<v-btn color="blue darken-1" text :loading="loadingBtn" :disabled="loadingBtn" type="submit">Save</v-btn>
			</v-card-actions>
		</v-form>
    </v-card>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

export default {
	data() {
		return {
			streamers: [],
			v_model_category: '',
			v_model_server_host: '',
			v_model_server_reward_category: '',
			v_model_server_reward_item: '',
			categories: [],
			categories_names: [],
			server_hosts_names: [],
			server_reward_categories_names: [],
			loadingBtn: false,
			title: '',
			description: '',
			repeatable: false,
			steam_auth_required: false,
			has_max_redeems: false,
			has_max_redeems_per_user: false,
			has_multiple_redeems: false,
			notify_in_chat: false,
			cooldown: 0,
			urls: [],
			max_redeems: 0,
			max_redeems_per_user: 0,
		}
	},

	validations() {
		return {
			title: {
				required
			},
			description: {
				required
			},
			v_model_category: {
				required
			}
		};
	},

	computed: {
		titleErrors() {
			const errors = [];
			if (!this.$v.title.$dirty) return errors;
			!this.$v.title.required && errors.push('Title is required.');
			return errors;
		},

		descriptionErrors() {
			const errors = [];
			if (!this.$v.description.$dirty) return errors;
			!this.$v.description.required && errors.push('Description is required.');
			return errors;
		},

		categoryErrors() {
			const errors = [];
			if (!this.$v.v_model_category.$dirty) return errors;
			!this.$v.v_model_category.required && errors.push('Category is required.');
			return errors;
		},
	},

	async mounted() {  
		this.streamers.forEach(streamer => streamer.points = null);
		await this.fetchCategories();
		await this.fetchStreamers();
	},

	methods: {
		AddImageURL () {
			this.urls.push('');
		},

		loadSubCat (category) {
			this.server_reward_categories_names.length = 0;

			let category_ = this.categories.find(cat => cat.content === category);

			category_.server_reward_categories.forEach(srv_reward_cat => {
				this.server_reward_categories_names.push(srv_reward_cat);
				this.sub_category = srv_reward_cat;
			});

			category_.servers.forEach(server => {
				this.server_hosts_names.push(server.name);
				this.v_model_server_host = server;
			});
		},

		async SaveReward () {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				this.loadingBtn = true;
				this.urls = this.urls.filter(url => url !==  '');

				let streamers_req = this.streamers.map(streamer => {
					return {
						id: streamer.id,
						twitch_id: streamer.twitch_id || null,
						streamer_shop: streamer.nickname,
						points: streamer.points,
						shorthand: streamer.shorthand
					}
				});
				
				try {
					const request = await fetch(this.$store.state.base_url + '/rewards/new', {
						method: 'POST',
						body: JSON.stringify({
							title: this.title,
							description: this.description,
							points_requirements: streamers_req,
							repeatable: this.repeatable,
							urls: this.urls,
							max_redeems: this.max_redeems,
							category: {
								category: this.v_model_category,
								server_host: this.v_model_server_host,
								server_reward_category: this.v_model_server_reward_category,
								server_reward_item: this.v_model_server_reward_item,
							},
							steam_auth_required: this.steam_auth_required,
							has_max_redeems: this.has_max_redeems,
							has_multiple_redeems: this.has_multiple_redeems,
							notify_in_chat: this.notify_in_chat,
							extra: this.extra,
							cooldown: this.cooldown
						}),
						headers: {
							"Content-type" : "application/json; charset=UTF-8",
							"Authorization" : this.$cookies.get('guardian_key_session'),
						}
					});
					const res = await request.json();
					
					if (request.ok) {	
						if (res.isRewardAdded) {
							this.$v.title.$reset();
							this.$v.description.$reset();
							this.title = '';
							this.description = '';

							this.loadingBtn = false;

							this.$notify({
								group: 'main',
								type: 'success',
								title: this.$store.state.app_name,
								text: 'Reward created! You can enable it for users to redeem!'
							});

							this.$emit('NewRewardAdded');
						}
					}
					else if (request.status === 401) {
						res.forEach((msg) => {
							this.$notify({
								group: 'main',
								type: 'error',
								title: this.$store.state.app_name,
								text: msg.error
							});
						});

						this.loadingBtn = false;
					}
					else {
						if (res.error) {
							if (this.$store.state.error_types.includes(res.error)) {
								this.sessionExpiredHandler(res);
							}
							else {
								throw res.error;
							}
						}
					}
				}
				catch (error) {
					this.$notify({
						group: 'main',
						type: 'error',
						title: this.$store.state.app_name,
						text: error.message
					});
				}
			}
		},

		async fetchCategories () {
			try {
				const request = await fetch(this.$store.state.base_url + '/categories', {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});
				const res = await request.json();

				if (request.ok && request.status === 200) {
					if (res.categories) {
						res.categories.forEach(category => {
							this.categories_names.push(category.content);
							this.categories.push(category);
						});
					}
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message
				});
			}
			finally {
				this.categoriesLoading = false;
			}
		},

		async fetchStreamers() {
			try {
				const request = await fetch(`${this.$store.state.base_url}/enrolled/streamers`, {
					method: 'GET',
					headers: {
						"Authorization" : this.$cookies.get('guardian_key_session'),
					}
				});

				const res = await request.json();

				if (request.ok) {
					if (res.streamers) this.streamers = res.streamers;
				}
				else {
					if (res.error) {
						if (this.$store.state.error_types.includes(res.error)) {
							this.sessionExpiredHandler(res);
						}
						else {
							throw res.error;
						}
					}
				}
			} 
			catch (error) {
				this.$notify({
					group: 'main',
					type: 'error',
					title: this.$store.state.app_name,
					text: error.message
				});
			}
			finally {
				this.rewardsLoading = false;
			}
		},
	},
}
</script>

<style>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
</style>